<template>
  <div class="register">
    <v-card color="primary" tile>
      <v-row align="center" justify="center">
        <img
          src="../../assets/logoWhite.svg"
          alt=""
          height="30"
          class="card_image"
        />
      </v-row>
      <v-row align="center" justify="center">
        <v-card
          class="text_field_container"
          height="85vh"
          rounded="lg"
          :width="returnWidth"
        >
          <h4 class="mb-8">Create your Ajo Acoount</h4>

          <ValidationObserver>
            <v-form ref="form" @submit.prevent="registerUser">
              <ValidationProvider
                name="fullname"
                rules="required"
                v-slot="{ errors }"
              >
                <span id="error" v-if="errors.length > 0">{{ errors[0] }}</span>
                <p>Fullname</p>
                <v-text-field
                  placeholder="Firstname Lastname"
                  name="fullname"
                  v-model="fullname"
                  outlined
                  color="primary"
                ></v-text-field>
              </ValidationProvider>
              <ValidationProvider
                name="email"
                rules="required"
                v-slot="{ errors }"
              >
                <span id="error" v-if="errors.length > 0">{{ errors[0] }}</span>
                <p>Email</p>
                <v-text-field
                  placeholder="Enter Email Address"
                  name="email"
                  v-model="email"
                  outlined
                  color="primary"
                ></v-text-field>
              </ValidationProvider>
              <ValidationProvider
                rules="required|phonenumber:0|min:11|max:11"
                v-slot="{ errors }"
                name="phonenumber"
              >
                <p>Phone</p>
                <span id="error" v-if="errors.length > 0">{{ errors[0] }}</span>

                <v-text-field
                  v-model="phonenumber"
                  placeholder="Enter a valid Phonenumber"
                  outlined
                  color="primary"
                ></v-text-field>
              </ValidationProvider>
              <ValidationProvider
                name="address"
                rules="required"
                v-slot="{ errors }"
              >
                <span id="error" v-if="errors.length > 0">{{ errors[0] }}</span>
                <p>Address</p>
                <v-text-field
                  placeholder="address"
                  name="address"
                  v-model="address"
                  outlined
                  color="primary"
                ></v-text-field>
              </ValidationProvider>
              <ValidationProvider
                name="role"
                rules="required"
                v-slot="{ errors }"
              >
                <span id="error" v-if="errors.length > 0">{{ errors[0] }}</span>
                <p>Postal Code</p>
                <v-text-field
                  placeholder="Enter Your Postal Code"
                  solo
                  outlined
                  flat
                  v-model="postalCode"
                ></v-text-field>
              </ValidationProvider>
              <ValidationProvider
                name="password"
                rules="required|min:6|passwordConfirmation:@check"
                v-slot="{ errors }"
              >
                <span id="error" v-if="errors.length > 0">{{ errors[0] }}</span>
                <p>Password</p>
                <v-text-field
                  placeholder="Password"
                  name="password"
                  v-model="password"
                  outlined
                  color="primary"
                ></v-text-field>
              </ValidationProvider>
              <ValidationProvider
                name="check"
                rules="required|min:6|passwordConfirmation:@password"
                v-slot="{ errors }"
              >
                <span id="error" v-if="errors.length > 0">{{ errors[0] }}</span>
                <p>Confirm Password</p>
                <v-text-field
                  placeholder="Confirm Password"
                  name="password"
                  v-model="passwordCheck"
                  outlined
                  color="primary"
                ></v-text-field>
              </ValidationProvider>

              <v-btn
                color="primary"
                type="submit"
                class="auth_btn"
                depressed
                :loading="loading"
              >
                CREATE AN ACCOUNT
              </v-btn>
            </v-form>
          </ValidationObserver>
          <v-row align="center" justify="center" class="mt-2">
            <p class="mb-0">Already have an account?</p>
            <span class="ml-2 login_text" @click.prevent="login"
              >Login</span
            ></v-row
          >
        </v-card>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      value: true,
      phonenumber: "",
      password: "",
      passwordCheck: "",
      address: "",
      postalCode: "",
      fullname: "",
      email: "",
      loading: false,
    };
  },
  computed: {
    returnWidth() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "100vw";
      } else {
        return "30vw";
      }
    },
  },
  methods: {
    login() {
      this.$router.push("/login");
    },
    async registerUser() {
      this.loading = true;
      let user = {
        email: this.email,
        first_name: this.fullname.split(" ")[0],
        last_name: this.fullname.split(" ")[1],
        phone: this.phonenumber,
        password: this.password,
        address: this.address,
        role: "Agent",
      };

      sessionStorage.setItem("register", JSON.stringify(user));
      let number = "+234" + user.phone.toString().substring(1);
      this.$router.push({ path: "/verify", query: { phone: number } });
    },
  },
  clearFields() {
    this.phonenumber = "";
    this.role = "";
    this.password = "";
    this.passwordCheck = "";
    this.address = "";
  },
};
</script>

<style scoped>
p {
  margin-bottom: 1px;
}
.auth_btn {
  height: 3rem !important;
  width: 100%;
}
.login_text {
  cursor: pointer;
  font-weight: 600;
}
.card_image {
  position: relative;
  top: 2rem;
}
#error {
  font-size: 14px !important;
  color: red !important;
}

.create_account_btn {
  position: fixed;
  left: 40%;
}
.text_field_container {
  padding: 30px 40px;
  overflow-y: auto;
}
.text_field_container {
  font-weight: 500;
  color: #272253;
  font-size: 13px;
  margin-bottom: 2px !important;
}
.text_field_container h4 {
  text-align: center;
}
.register {
  height: 100vh;
}
.register >>> .v-card {
  height: -webkit-fill-available;
  box-shadow: 1px 0px 25px 0px rgba(50, 50, 50, 0.2) !important;
}
.register >>> .text_field_container {
  position: relative;
  top: 5rem;
}
.register >>> .left_column {
  height: 500px !important;
}
.register >>> .right_column {
  background-color: var(--bg-color);
}
.register
  >>> .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
  > .v-input__control
  > .v-input__slot,
.v-text-field.v-text-field--enclosed .v-text-field__details {
  background: #f7f9fc;
  font-size: 12px;
  margin-bottom: 2rem;
  font-weight: 600;
}
.register >>> fieldset {
  border: unset !important;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(255, 255, 255) !important;
  border-radius: 5px !important;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px !important;
}

::-webkit-scrollbar-thumb:hover {
  background: #000000 !important;
}
</style>