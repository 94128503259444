<template>
  <v-card :width="returnWidth" class="pa-6 kyc" rounded="lg">
    <div align="center" justify="center">
      <h5>Welcome {{ firstName }}</h5>
    </div>
    <span>BVN</span>
    <v-text-field
      placeholder="Enter 16 Digit BVN"
      v-model="bvn"
      outlined
      color="primary"
    ></v-text-field>
    <span>CAC Number</span>
    <v-text-field
      placeholder="Enter CAC Number"
      v-model="cac"
      outlined
      color="primary"
    ></v-text-field>
    <span>Upload CAC Document</span>
    <div
      v-if="files.length === 0"
      class="file_drag mt-3"
      :style="{ 'background-color': color }"
      @dragleave="dragLeave"
      @dragover.prevent="dragOver"
      @drop.prevent="drop"
      @click="() => getFile()"
      align="center"
      justify="center"
    >
      <v-icon> mdi-cloud-upload-outline </v-icon>
      <h4>Drag and Drop or <span class="span_class">choose file</span></h4>
      <input
        ref="fileInput"
        accept="image/*"
        type="file"
        @change="onChange"
        style="display: none"
      />
      <p>240x346 px Recommended, max size 1MB</p>
    </div>
    <div v-else class="my-4">
      <div class="d-flex">
        <img :src="image_src" width="100" height="100" class="view_image" />
        <div class="mx-4">
          <h5>{{ files[0].name }}</h5>
          <p class="mt-2">{{ calculateFileSize(files[0].size) }}</p>
          <div class="d-flex mt-4">
            <v-btn color="black" class="pa-3" depressed @click="() => getFile()"
              >Replace</v-btn
            >
            <v-btn
              color="#F5F7F8"
              light
              depressed
              class="ml-2 pa-3"
              @click="() => deleteFile()"
              >Delete</v-btn
            >
          </div>
        </div>
      </div>
      <input
        ref="fileInput"
        accept="image/*"
        type="file"
        @change="onChange"
        style="display: none"
      />
    </div>

    <div align="center" justify="center">
      <v-btn color="primary" class="btn"> VALIDATE ACCOUNT </v-btn>
    </div>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      bvn: "",
      cac: "",
      color: "#f7f9fc",
      files: [],
      image_src: "",
    };
  },
  methods: {
    getFile() {
      this.$refs.fileInput.click();
    },
    deleteFile() {
      this.files = [];
    },
    dragLeave(event) {
      this.color = "#FAFAFA";
    },
    dragOver(event) {
      this.color = "grey";
    },
    drop(event) {
      this.color = "#FAFAFA";
      if (event.dataTransfer.files[0].size <= 100000) {
        this.files = event.dataTransfer.files;
        this.image_src = URL.createObjectURL(event.dataTransfer.files[0]);
      } else {
        // this.$store.dispatch("snackBar/displaySnackBar", {
        //   success: false,
        //   snackBarMessage: "File exceeded 1mb",
        // });
        window.alert("File Size Too Large");
      }
    },
    onChange(event) {
      event.preventDefault();
      if (event.target.files[0].size <= 100000) {
        this.files = event.target.files;
        this.image_src = URL.createObjectURL(event.target.files[0]);
      } else {
        // this.$store.dispatch("snackBar/displaySnackBar", {
        //   success: false,
        //   snackBarMessage: "File exceeded 1mb",
        // });
        window.alert("File Size Too Large");
      }
    },
    calculateFileSize(size) {
      return size / 100000 + "mb";
    },
  },
  computed: {
    returnWidth() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "100vw";
      } else {
        return "30vw";
      }
    },

    firstName() {
      return "UJAY";
    },
  },
};
</script>

<style scoped>
.file_drag {
  cursor: pointer;
  padding: 10px 0;
  margin-bottom: 20px;
  border-radius: 4px;
}
.btn {
  width: 100%;
  height: 3rem !important;
}
.kyc p {
  margin: 5px 0;
  line-height: 18px;
  font-size: 12px;
  color: #272253;
}
.kyc span {
  font-size: 12px;
  color: #272253;
  font-weight: 600;
}
.kyc >>> .col {
  padding: 0 !important;
}
.kyc
  >>> .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
  > .v-input__control
  > .v-input__slot,
.v-text-field.v-text-field--enclosed .v-text-field__details {
  background: #f7f9fc;
  font-size: 12px;
  margin-bottom: 2rem;
}
.kyc >>> fieldset {
  border: unset;
}
</style>

</style>