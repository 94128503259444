<template>
  <v-card
    :width="returnWidth"
    class="pa-6 email"
    rounded="lg"
    :height="returnHeight"
  >
    <div align="center" justify="center">
      <h4>Almost Done</h4>
      <p>Check your email to verify your Ajopoint account.</p>
      <img src="../../../assets/verify.svg" alt="" class="my-4" />
    </div>
    <!-- <div class="my-10" align="center" justify="center">
      <v-btn color="primary" large class="ml-4"> BACK HOME </v-btn>
    </div> -->
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      email: "",
    };
  },
  computed: {
    returnWidth() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "100vw";
      } else {
        return "30vw";
      }
    },
    returnHeight() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "40vh";
      } else {
        return "40vh";
      }
    },
  },
};
</script>

<style scoped>
.email p {
  margin: 5px 0;
  line-height: 18px;
  font-size: 12px;
  color: #272253;
}
.email span {
  font-size: 12px;
  color: #272253;
  font-weight: 600;
}
.token >>> .col {
  padding: 0 !important;
}
.email
  >>> .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
  > .v-input__control
  > .v-input__slot,
.v-text-field.v-text-field--enclosed .v-text-field__details {
  background: #f7f9fc;
  font-size: 12px;
  margin-bottom: 2rem;
}
.email >>> fieldset {
  border: unset;
}
</style>

</style>