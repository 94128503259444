<template>
  <div>
    <v-card
      :width="returnWidth"
      :height="returnHeight"
      class="pa-6 token"
      rounded="lg"
      v-if="!loading"
    >
      <h5>Verify Your Number</h5>
      <p>
        A 10-digit token has been sent to 0806 670 1121 to verify user number
        with the token now.
      </p>
      <v-row class="mt-10">
        <v-col md="6" sm="8">
          <span>Token</span>
          <v-text-field
            placeholder="Enter Token"
            name="fullname"
            v-model="token"
            outlined
            color="primary"
          ></v-text-field>
        </v-col>
        <v-col md="6" sm="4" align="center" justify="center">
          <img
            src="../../../assets/verify.svg"
            alt=""
            :height="height"
            class="card_image"
          />
        </v-col>
      </v-row>
      <div class="d-flex">
        <v-btn outlined color="primary" large> BACK </v-btn>
        <v-btn
          :loading="verifyloading"
          color="primary"
          large
          class="ml-4"
          @click.prevent="verifyNumber()"
        >
          VERIFY NUMBER
        </v-btn>
      </div>
    </v-card>
    <v-card :width="returnWidth" class="px-4 saver" v-else :loading="loading">
      <v-row justify="center" class="py-10">
        <h5>Sending Verification Token</h5>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { sendToken, verifyToken } from "../../../agent/services/api-services";
export default {
  data() {
    return {
      token: "",
      loading: true,
      verifyloading: false,
    };
  },
  computed: {
    returnWidth() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "100vw";
      } else {
        return "30vw";
      }
    },
    returnHeight() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "40vh";
      } else {
        return "40vh";
      }
    },
    height() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "60px";
      } else {
        return "100px";
      }
    },
  },
  created() {
    if (this.$route.query.phone !== undefined) {
      this.sendUserToken();
    } else {
      console.log("No Number");
    }
  },
  methods: {
    verifyNumber() {
      let request_id = JSON.parse(sessionStorage.getItem("token")).message;
      let number = "0" + this.$route.query.phone.toString().substring(4);
      let data = {
        request_id,
        code: this.token,
        phone: number,
      };
      this.verifyloading = true;
      verifyToken(data).then(
        (res) => {
          this.verifyloading = false;
          console.log(res);
          this.$router.push({ path: "/verify", query: { type: "success" } });
        },
        (err) => {
          this.verifyloading = false;
          console.error(err);
        }
      );
    },
    sendUserToken() {
      let number = this.$route.query.phone;
      let data = {
        recipient: number,
      };

      sendToken(data).then(
        (res) => {
          this.loading = false;
          sessionStorage.setItem("token", JSON.stringify(res.data));
          console.log(res);
        },
        (err) => {
          this.loading = false;
          console.error(err);
        }
      );
    },
  },
};
</script>

<style scoped>
.token p {
  margin: 5px 0;
  line-height: 18px;
  font-size: 12px;
  color: #272253;
}
.token span {
  font-size: 12px;
  color: #272253;
  font-weight: 600;
}
.token >>> .col {
  padding: 0 !important;
}
.token
  >>> .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
  > .v-input__control
  > .v-input__slot,
.v-text-field.v-text-field--enclosed .v-text-field__details {
  background: #f7f9fc;
  font-size: 12px;
  margin-bottom: 2rem;
}
.token >>> fieldset {
  border: unset !important;
}
</style>

</style>