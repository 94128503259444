<template>
  <v-card
    width="400"
    height="550px"
    class="success-top mx-auto"
    color="#FFF0CE"
  >
    <v-row justify="center" align-content="center">
      <v-avatar size="56" class="mt-16">
        <img
          alt="user"
          src="https://cdn.pixabay.com/photo/2020/06/24/19/12/cabbage-5337431_1280.jpg"
        />
      </v-avatar>

      <v-card-text>
        <h3 class="text-center">{{ header }}</h3>
      </v-card-text>

      <v-card-text>
        <p class="text-center">
          {{ body }}
        </p>
      </v-card-text>

      <v-btn
        class="px-6 py-5"
        depressed
        width="200"
        @click="closeModal"
        color="primary"
        >{{ buttonText }}</v-btn
      >
    </v-row>
  </v-card>
</template>

<script>
export default {
  methods: {
    closeModal() {
      this.$emit("closeModal");
      this.actionCall();
    },
  },
  props: {
    header: String,
    body: String,
    buttonText: String,
    actionCall: Function,
  },
};
</script>

<style scoped>
.confirm-email-background {
  background-color: #fff0ce !important;
}
</style>