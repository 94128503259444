<template>
  <div class="verify">
    <v-card color="primary" tile>
      <v-row align="center" justify="center">
        <img
          src="../../../assets/logoWhite.svg"
          alt=""
          height="30"
          class="card_image"
        />
      </v-row>
      <v-row align="center" justify="center">
        <component :is="returnComponent" class="compnentContainer"></component
      ></v-row>
    </v-card>
  </div>
</template>

<script>
import Token from "./Token";
import Email from "./Email";
import KYC from "./KYC";
import Pin from "./Pin";
export default {
  components: {
    Token,
    Email,
    KYC,
    Pin,
  },
  computed: {
    returnComponent() {
      if (
        this.$route.query.type !== undefined &&
        this.$route.query.type === "success"
      ) {
        return Email;
      } else {
        return Pin;
      }
    },
  },
};
</script>

<style scoped>
.verify {
  height: 100vh;
}
.verify >>> .v-card {
  height: -webkit-fill-available;
}
.card_image {
  position: relative;
  top: 2rem;
}
.compnentContainer {
  position: relative;
  top: 6rem;
}
</style>