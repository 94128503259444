<template>
  <div>
    <v-card :width="returnWidth" class="pa-6 pin" rounded="lg">
      <div align="center" justify="center" class="mb-10">
        <h4>Setup Transaction Pin</h4>
      </div>
      <span>New Pin</span>
      <div class="d-flex">
        <v-text-field
          v-for="(pin, index) in newPin"
          :ref="`new${index}`"
          :key="index"
          placeholder="-"
          v-model="pin.field"
          outlined
          color="primary"
          @input="focusInput(index)"
          type="password"
        ></v-text-field>
      </div>

      <span>Confirm Pin</span>
      <div class="d-flex">
        <v-text-field
          v-for="(pin, index) in confirmPin"
          :ref="`confirm${index}`"
          :key="index"
          placeholder="-"
          v-model="pin.field"
          outlined
          color="primary"
          @input="focusInputConfirm(index)"
          type="password"
        ></v-text-field>
      </div>

      <div align="center" justify="center">
        <v-btn
          color="primary"
          class="btn"
          :loading="loading"
          @click.prevent="registerUser"
          :disabled="disableBtn"
        >
          VALIDATE ACCOUNT
        </v-btn>
      </div>
    </v-card>
    <v-dialog v-model="dialog" persistent max-width="400">
      <Modal
        @closeModal="closeModalScreen"
        :header="header"
        :body="body"
        :buttonText="buttonText"
        :actionCall="actionCall"
      />
    </v-dialog>
  </div>
</template>

<script>
import Modal from "@/ui/modal/Modal.vue";
import { register } from "../../services/auth-services";
export default {
  data() {
    return {
      header: "Confirm your Email Address",
      body:
        "Your account has been created successfully. Kindly confirm your mail to log in to the platform",
      dialog: false,
      buttonText: "Go Back",
      userPin: "",
      actionCall: null,
      loading: false,
      newPin: [
        { field: "", disable: false },
        { field: "", disable: false },
        { field: "", disable: false },
        { field: "", disable: false },
      ],
      confirmPin: [
        { field: "", disable: true },
        { field: "", disable: true },
        { field: "", disable: true },
        { field: "", disable: true },
      ],
      bvn: "",
      cac: "",
      color: "#f7f9fc",
      files: [],
      image_src: "",
      disableBtn: true,
    };
  },
  components: {
    Modal,
  },
  methods: {
    async registerUser() {
      this.loading = true;
      this.createPin();
      let user = JSON.parse(sessionStorage.getItem("register"));
      user.transfer_pin = this.userPin;
      await register(user).then(
        (res) => {
          this.loading = false;
          this.dialog = true;
          this.buttonText = "Go To Login";
          this.actionCall = this.goToLogin;
          this.clearStorage();
        },
        (err) => {
          let keys = Object.keys(err.response.data);
          this.loading = false;
          this.header = "Registeration Failed";
          this.body = err.response.data[keys[0]][0];
          this.dialog = true;
          this.actionCall = this.goToRegister;
          console.log(err.response.data);
          this.clearStorage();
        }
      );
    },
    goToLogin() {
      this.$router.push({ path: "/login" });
    },
    goToRegister() {
      this.$router.push({ path: "/register" });
    },
    closeModalScreen() {
      this.dialog = false;
      this.clearPin();
      this.$router.push({ path: "/register" });
      this.clearStorage();
    },
    clearStorage() {
      sessionStorage.removeItem("register");
    },
    clearPin() {
      this.newPin = [
        { field: "", disable: false },
        { field: "", disable: false },
        { field: "", disable: false },
        { field: "", disable: false },
      ];
      this.confirmPin = [
        { field: "", disable: true },
        { field: "", disable: true },
        { field: "", disable: true },
        { field: "", disable: true },
      ];
    },
    createPin() {
      let data = this.confirmPin.reduce(
        (accumulator, currentValue) => accumulator + currentValue.field,
        ""
      );

      this.userPin = data;
    },
    focusInput(index) {
      if (this.newPin[index].field !== "" && index !== 3) {
        this.$refs["new" + (index + 1)][0].focus();
      }
      this.validatePin();
    },
    focusInputConfirm(index) {
      if (this.confirmPin[index].field !== "" && index !== 3) {
        this.$refs["confirm" + (index + 1)][0].focus();
      }
      this.validatePin();
    },
    validatePin() {
      this.newPin.forEach((element, index) => {
        if (element.field !== this.confirmPin[index].field) {
          this.confirmPin[index].disable = true;
        } else {
          this.confirmPin[index].disable = false;
        }
      });

      this.disableBtn =
        this.confirmPin[0].disable ||
        this.confirmPin[1].disable ||
        this.confirmPin[2].disable ||
        this.confirmPin[3].disable;
    },
  },
  watch: {},
  computed: {
    returnWidth() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "100vw";
      } else {
        return "30vw";
      }
    },

    isDisabled() {
      return function (index, field) {
        return this.newPin[index].disable;
      };
    },

    firstName() {
      return "UJAY";
    },
  },
};
</script>

<style scoped>
.file_drag {
  cursor: pointer;
  padding: 10px 0;
  margin-bottom: 20px;
  border-radius: 4px;
}
.btn {
  width: 100%;
  height: 3rem !important;
}
.pin p {
  margin: 5px 0;
  line-height: 18px;
  font-size: 12px;
  color: #272253;
}
.pin span {
  font-size: 12px;
  font-weight: 600;
}
.pin >>> .col {
  padding: 0 !important;
}
.pin
  >>> .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
  > .v-input__control
  > .v-input__slot,
.v-text-field.v-text-field--enclosed .v-text-field__details {
  background: #f7f9fc;
  font-size: 12px;
  margin-bottom: 2rem;
  width: 70px;
}
.pin >>> input {
  text-align: center;
}
.pin >>> fieldset {
  border: unset;
}
</style>
