import axios from 'axios'

let AUTH_URL = "https://staging-ajopoint.herokuapp.com"

export const login = async (user) => {
    return await axios
        .post(AUTH_URL + '/api/token/jwt', user)
        .then(response => {

            const base64Url = response.data.access.split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));


            if (response) {
                sessionStorage.setItem('ajoData', jsonPayload)
                sessionStorage.setItem('ajoToken', JSON.stringify(response.data))
            }
            return Promise.resolve(JSON.parse(jsonPayload));
        });
}

export const logout = () => {
    sessionStorage.removeItem('ajoToken');
    sessionStorage.removeItem('ajoData')
    window.location.href = "/login";
}

export const register = user => {
    return axios.post(AUTH_URL + "/api/accounts/register/", user);
};

export const createUserPin = data => {
    return axios.post(AUTH_URL + "/api/user/setup_initial_pin/", data);
};