var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"register"},[_c('v-card',{attrs:{"color":"primary","tile":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('img',{staticClass:"card_image",attrs:{"src":require("../../assets/logoWhite.svg"),"alt":"","height":"30"}})]),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-card',{staticClass:"text_field_container",attrs:{"height":"85vh","rounded":"lg","width":_vm.returnWidth}},[_c('h4',{staticClass:"mb-8"},[_vm._v("Create your Ajo Acoount")]),_c('ValidationObserver',[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.registerUser($event)}}},[_c('ValidationProvider',{attrs:{"name":"fullname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(errors.length > 0)?_c('span',{attrs:{"id":"error"}},[_vm._v(_vm._s(errors[0]))]):_vm._e(),_c('p',[_vm._v("Fullname")]),_c('v-text-field',{attrs:{"placeholder":"Firstname Lastname","name":"fullname","outlined":"","color":"primary"},model:{value:(_vm.fullname),callback:function ($$v) {_vm.fullname=$$v},expression:"fullname"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(errors.length > 0)?_c('span',{attrs:{"id":"error"}},[_vm._v(_vm._s(errors[0]))]):_vm._e(),_c('p',[_vm._v("Email")]),_c('v-text-field',{attrs:{"placeholder":"Enter Email Address","name":"email","outlined":"","color":"primary"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}])}),_c('ValidationProvider',{attrs:{"rules":"required|phonenumber:0|min:11|max:11","name":"phonenumber"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',[_vm._v("Phone")]),(errors.length > 0)?_c('span',{attrs:{"id":"error"}},[_vm._v(_vm._s(errors[0]))]):_vm._e(),_c('v-text-field',{attrs:{"placeholder":"Enter a valid Phonenumber","outlined":"","color":"primary"},model:{value:(_vm.phonenumber),callback:function ($$v) {_vm.phonenumber=$$v},expression:"phonenumber"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(errors.length > 0)?_c('span',{attrs:{"id":"error"}},[_vm._v(_vm._s(errors[0]))]):_vm._e(),_c('p',[_vm._v("Address")]),_c('v-text-field',{attrs:{"placeholder":"address","name":"address","outlined":"","color":"primary"},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(errors.length > 0)?_c('span',{attrs:{"id":"error"}},[_vm._v(_vm._s(errors[0]))]):_vm._e(),_c('p',[_vm._v("Postal Code")]),_c('v-text-field',{attrs:{"placeholder":"Enter Your Postal Code","solo":"","outlined":"","flat":""},model:{value:(_vm.postalCode),callback:function ($$v) {_vm.postalCode=$$v},expression:"postalCode"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"password","rules":"required|min:6|passwordConfirmation:@check"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(errors.length > 0)?_c('span',{attrs:{"id":"error"}},[_vm._v(_vm._s(errors[0]))]):_vm._e(),_c('p',[_vm._v("Password")]),_c('v-text-field',{attrs:{"placeholder":"Password","name":"password","outlined":"","color":"primary"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"check","rules":"required|min:6|passwordConfirmation:@password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(errors.length > 0)?_c('span',{attrs:{"id":"error"}},[_vm._v(_vm._s(errors[0]))]):_vm._e(),_c('p',[_vm._v("Confirm Password")]),_c('v-text-field',{attrs:{"placeholder":"Confirm Password","name":"password","outlined":"","color":"primary"},model:{value:(_vm.passwordCheck),callback:function ($$v) {_vm.passwordCheck=$$v},expression:"passwordCheck"}})]}}])}),_c('v-btn',{staticClass:"auth_btn",attrs:{"color":"primary","type":"submit","depressed":"","loading":_vm.loading}},[_vm._v(" CREATE AN ACCOUNT ")])],1)],1),_c('v-row',{staticClass:"mt-2",attrs:{"align":"center","justify":"center"}},[_c('p',{staticClass:"mb-0"},[_vm._v("Already have an account?")]),_c('span',{staticClass:"ml-2 login_text",on:{"click":function($event){$event.preventDefault();return _vm.login($event)}}},[_vm._v("Login")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }